<template>
  <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
  >
    <b-form
        class="list-view product-checkout"
        @submit.prevent="handleSubmit(onSubmit)"
    >
      <div class="list-view product-checkout">
        <b-card no-body>
          <b-card-header class="flex-column align-items-start">
            <b-card-title> {{ $t('Payment Options') }}</b-card-title>
            <b-card-text class="text-muted mt-25">
              {{ $t('Be sure to click on correct payment option') }}
            </b-card-text>
          </b-card-header>
          <b-card-body>
            <ValidationProvider rules="required" v-slot="{ errors }" name="paymentmethod">
              <b-row>
                <b-col md="3"  v-for="paymentmethod in SHOP_PAYMENT_METHODS" v-if="SHOP_PAYMENT_METHODS">
                  <div class="form-group">
                    <div class="custom-control custom-radio">
                      <input type="radio"
                             :id="'paymentmethodId'+paymentmethod.id"
                             class="custom-control-input"
                             value="2"
                             v-model="methodValue"
                             :value="'paymentmethodId'+paymentmethod.id"
                             @change="setPaymentCheckout(paymentmethod)"
                      >
                      <label :for="'paymentmethodId'+paymentmethod.id" class="custom-control-label">
                        <b-col class="ml-1">
                          <strong class="text-left mr-1">
                            {{ paymentmethod.name }}
                          </strong>
                        </b-col>
                        <b-col class="ml-1">
                          <p v-if="paymentmethod.payment_type == 'dd'" class="text-muted">
                            **** **** **** <strong>{{ paymentmethod.last_four }}</strong>
                          </p>
                        </b-col>
                      </label>
                    </div>
                  </div>
                  <b-alert v-if="errors[0]" show variant="danger" class="py-1 px-2 col-md-6 ">
                    {{ $t('Please select a Payment Method to finalize the Order') }}
                  </b-alert>
                </b-col>
              </b-row>
            </ValidationProvider>
            <b-button
                :to="{name: 'payment-methods-add'}"
                size="sm"
                variant="outline-primary"
                class="btn-tour-skip mr-1 mt-1"
            >
              <feather-icon
                  icon="PlusIcon"
                  size="12"
              />
              <span class="mr-25 align-middle ml-1"> {{ $t('Add another Payment Method') }}</span>
            </b-button>
            <b-button
                variant="success"
                type="submit"
                class="mt-1 d-block"
            >
              {{ $t('Finalize Order') }}
            </b-button>
          </b-card-body>
        </b-card>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BRow,
  BAlert
} from 'bootstrap-vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BFormGroup,
    BFormRadio,
    BFormInput,
    BButton,
    BCol,
    BRow,
    BForm,
    BAlert,
    ValidationObserver,
    ValidationProvider,
  },

  setup(_, {emit}) {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {
    })

    const onSubmit = () => {
      emit('next-step')
    }
    return {
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },

  mounted() {
    this.GET_SHOP_PAYMENT_METHODS()
  },

  data() {
    return {
      methodValue: null
    }
  },

  methods: {
    ...mapMutations(['SET_PAYMENT_METHOD']),
    ...mapActions(['GET_SHOP_PAYMENT_METHODS']),
    setPaymentCheckout(method) {
      this.SET_PAYMENT_METHOD(method)
    },
  }, computed: {
    ...mapGetters(['SHOP_PAYMENT_METHODS'])
  }
}
</script>

<style lang="scss" scoped>
#cvv {
  width: auto;
}
</style>
