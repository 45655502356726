<template>
  <div class="amount-payable checkout-options" v-if="CHECKOUT_DETAILS.invoiceaddress && CHECKOUT_DETAILS.paymentmethod">
    <b-card :title="$t('Order summary')">
      <b-row>
        <b-col class="finalize-title">
          <h5 class="mb-2"> {{ $t('Price overview') }}</h5>
          <div class="details-title mb-1 ml-1" v-if="CART.total">
            <b-row>
              <span class="text-muted">{{ $t('Costs of') }} {{ getCreditsAmount() }} {{ $t('Credit Items') }}</span>
            </b-row>
            <b-row>
                <span class="text-muted mt-1">{{ $t('Price') }}:<strong class="ml-2">{{
                    CART.total
                  }} Euro</strong></span>
            </b-row>
          </div>
        </b-col>
        <b-col>
          <div class="invoice-details">
            <h5 class="mb-2"> {{ $t('Invoice Address Details') }}</h5>
            <div class="ml-1 text-muted">
              <b-row>
                  <span>  {{ CHECKOUT_DETAILS.invoiceaddress.firstname }},   {{
                      CHECKOUT_DETAILS.invoiceaddress.name
                    }}</span>
              </b-row>
              <b-row>
                  <span>  {{ CHECKOUT_DETAILS.invoiceaddress.street }}  {{
                      CHECKOUT_DETAILS.invoiceaddress.addressline_2
                    }}</span>
              </b-row>
              <b-row>
                  <span>  {{ CHECKOUT_DETAILS.invoiceaddress.postal }}  {{
                      CHECKOUT_DETAILS.invoiceaddress.city
                    }}</span>
              </b-row>
              <b-row>
                <b-button
                    size="sm"
                    variant="outline-primary"
                    class="btn-tour-skip mr-1 mt-1"
                    @click="goToTab(1)"
                >
                  <feather-icon
                      icon="MoreVerticalIcon"
                      size="12"
                  />
                  <span class="mr-25 align-middle"> {{ $t('Update Invoice Address') }}</span>

                </b-button>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col class="finalize-title mb-1">
          <div class="Payment-details">
            <h5 class="mb-2"> {{ $t('Payment Details') }} </h5>
            <div class="ml-1 text-muted">
              <b-row>
                <span v-if="CHECKOUT_DETAILS.paymentmethod.type == 'custom'">
                  Sepa
                </span>
                <span v-else>
                  {{ CHECKOUT_DETAILS.paymentmethod.name }}
                </span>
              </b-row>
              <b-row v-if="CHECKOUT_DETAILS.paymentmethod.type == 'custom'">
                <span>   **** **** ****  {{ CHECKOUT_DETAILS.paymentmethod.last_four }}</span>
              </b-row>
              <b-row>
                <b-button
                    size="sm"
                    variant="outline-primary"
                    class="btn-tour-skip mr-1 mt-1"
                    @click="goToTab(2)"
                >
                  <feather-icon
                      icon="MoreVerticalIcon"
                      size="12"
                  />
                  <span class="mr-10 align-middle"> {{ $t('Update Payment Details') }}</span>
                </b-button>
              </b-row>
            </div>

          </div>
        </b-col>
        <hr>
      </b-row>
      <hr>
      <b-row>
        <b-col class="invoice-details">
          <div class="details-title mb-1">
            <strong>{{ $t('Total') }}</strong>
          </div>
          <h5 class="detail-amt font-weight-bolder">
            {{ CART.total }} <span>Euro</span>
          </h5>
        </b-col>
      </b-row>
      <b-form-checkbox
          v-model="requiredTerms"
          class="custom-control-success mt-2 mb-1"
          :name="$t('AGB')"
      >
        {{ $t('I have read the conditional Terms and I agree to it') }}

      </b-form-checkbox>
      <b-alert show variant="danger" class="py-1 px-2 col-md-6 " v-if="requiredTerms == false">
          {{ $t('You have to accept the conditional Terms in order to finalize the Order') }}
      </b-alert>
      <b-button
          variant="success"
          type="submit"
          size="small"
          block
          class="checkout-button mt-2"
          @click="sendCart()"
      >
        {{ $t('Place Order') }}
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BAlert
} from 'bootstrap-vue'
import ECommerceCheckoutStepCartProducts from './ECommerceCheckoutStepCartProducts.vue'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {integer, required} from '@validations'
import Ripple from 'vue-ripple-directive'
import Cart from '@/views/admin/e-commerce/e-commerce-checkout/Cart'
import {mapActions, mapGetters, mapMutations} from "vuex";
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
  components: {
    BButton,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormCheckbox,
    BCol,
    BRow,
    BFormInvalidFeedback,
    ECommerceCheckoutStepCartProducts,
    ValidationProvider,
    ValidationObserver,
    Cart,
    BAlert,
    required,
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      requiredTerms: null,
    }
  },


  methods: {
    ...mapActions(['STORE_SHOP_CHECKOUT','UPDATE_CREDITS']),
    ...mapMutations(['SET_CART']),
    sendCart() {
      if (!this.requiredTerms) {
        this.requiredTerms = false
      } else {
        console.debug(this.CHECKOUT_DETAILS)
        this.STORE_SHOP_CHECKOUT(this.CHECKOUT_DETAILS)
            .then(response => {
              if (response.status === 200) {
                this.SET_CART({})

                //redirect to payment provider
                if (response.data.payment_action) {
                  window.location = response.data.payment_action
                }

                this.UPDATE_CREDITS();
                this.$swal({
                  title: this.$i18n.t('Order successfully submitted'),
                  icon: 'success',
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  result.isConfirmed && this.$router.push({name: 'buy-credits-list'})

                })
              }
            })
      }
    },

    goToTab(index) {
      this.$emit('changeTabCart', index)
    },

    getCreditsAmount() {
      let count = 0;

      this.CART.items.forEach(item => {
        count += item.qty * 1;
      });

      return count;
    }
  },


  computed: {
    ...mapGetters(['CART', 'CHECKOUT_DETAILS'])

  }
}

</script>

<style>
.finalize-title {
  margin-top: 2px;
  margin-left: 2px;
}

.checkout-button {
  width: 20%;
}
</style>
