<template>
  <div class="list-view product-checkout mt-0">
    <!-- Checkout Options -->
    <div class="checkout-options">
      <b-card class="checkout-step" :title="$t('Checkout')">
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <div class="price-details">
          <b-row v-if="CART.total">
            <b-col class="md-5 mt-2 detail-title detail-total">
              {{getCreditsAmount()}} {{ $t('Credit Items') }}
            </b-col>
          </b-row>
          <hr>
          <b-row>

            <b-col class="md-5 mt-2 detail-title detail-total">
              {{ $t('Total') }}
            </b-col>
            <b-col class="md-6 detail-amt font-weight-bolder">
              <div class="mt-2" >{{ CART.total }} <span class="text-muted">Euro</span></div>
            </b-col>
          </b-row>
          <hr>
          <b-button
              variant="success"
              size="small"
              block
              @click="$emit('next-step')"
              class="checkoutstep-button mt-3"
          >
            {{ $t('Proceed to checkout') }}
          </b-button>

        </div>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BButton, BCard, BCol, BFormCheckbox, BFormInput, BInputGroup, BInputGroupAppend, BRow, BFormInvalidFeedback } from 'bootstrap-vue'
import ECommerceCheckoutStepCartProducts from './ECommerceCheckoutStepCartProducts.vue'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {mapGetters} from "vuex";

export default {
  components: {
    BButton,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormCheckbox,
    BCol,
    BRow,
    BFormInvalidFeedback,
    ECommerceCheckoutStepCartProducts,
    ValidationProvider,
    ValidationObserver,
  },


  data() {
    return {
      credits: '',
      notification: '',
    }
  },
  computed: {
    ...mapGetters(['CART'])
  },
  methods: {
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
    getCreditsAmount() {
      let count = 0;

      this.CART.items.forEach(item => {
        count += item.qty * 1;
      });

      return count;
    }
  }
}


</script>

<style>
.checkout-step {

}

.checkoutstep-button {
  width: 60%;
}
</style>
