<template>
<!--  tets-->
  <form-wizard
      ref="refFormWizard"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      finish-button-text="Submit"
      back-button-text="Previous"
      hide-buttons
      class="checkout-form-wizard steps-transparent"
  >
    <tab-content
        :title="$t('Cart')"
        icon="feather icon-shopping-cart">
      <div class="cart-content">
        <b-row>
          <b-col md="8">
            <e-commerce-checkout-step-cart-products
            />
          </b-col>
          <b-col md="4">
            <e-commerce-checkout-step-cart
                @next-step="formWizardNextStep"
            />
          </b-col>
        </b-row>
      </div>
    </tab-content>
    <tab-content
        :title="$t('Address')"
        icon="feather icon-home">
      <e-commerce-checkout-step-address
          @next-step="formWizardNextStep"/>
    </tab-content>
    <tab-content
        :title="$t('Payment')"
        icon="feather icon-credit-card"
    >
      <e-commerce-checkout-step-payment
          @next-step="formWizardNextStep"
      />
    </tab-content>
    <tab-content
        :title="$t('Complete order')"
        icon="feather icon-shopping-bag">
      <e-commerce-finalize
          v-on:changeTabCart="changeTab"
          @sendCart="formWizardNextStep"/>
    </tab-content>

  </form-wizard>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import {FormWizard, TabContent} from 'vue-form-wizard'
import {ref} from '@vue/composition-api'
import ECommerceCheckoutStepPayment from '@/views/admin/e-commerce/e-commerce-checkout/ECommerceCheckoutStepPayment'
import ECommerceCheckoutStepCart from '@/views/admin/e-commerce/e-commerce-checkout/ECommerceCheckoutStepCart'
import ECommerceCheckoutStepAddress from '@/views/admin/e-commerce/e-commerce-checkout/ECommerceCheckoutStepAddress'
import ECommerceCheckoutStepCartProducts
  from '@/views/admin/e-commerce/e-commerce-checkout/ECommerceCheckoutStepCartProducts'
import ECommerceFinalize from '@/views/admin/e-commerce/e-commerce-checkout/ECommerceFinalize'
import {SchemaForm, useSchemaForm} from 'formvuelate'
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  components: {
    FormWizard,
    TabContent,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    SchemaForm,
    useSchemaForm,
    ECommerceCheckoutStepCart,
    ECommerceCheckoutStepAddress,
    ECommerceCheckoutStepPayment,
    ECommerceCheckoutStepCartProducts,
    ECommerceFinalize,
  },


  setup() {
    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }
    return {
      refFormWizard,

      formWizardNextStep,

    }
  },

  computed: {
    ...mapGetters(['checkoutTabIndex'])

  },
  methods: {
    changeTab(index) {
      this.$refs.refFormWizard.changeTab(0, index)
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '../../../../../node_modules/vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" >
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
li.active .wizard-icon-container{
  background: #ad8a60 !important;
  opacity: 0.7;


}
[dir] .vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked{
  box-shadow: none;
}
.stepTitle.active{
  color: #ad8a60 !important;;
}

[dir] .steps-transparent .wizard-tab-content {
  background: none;
  box-shadow: none;
  padding: 0;
}

[dir] .steps-transparent.vue-form-wizard .wizard-navigation .wizard-nav {
  padding-left: 0;
}
</style>
