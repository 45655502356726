<template>
  <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
  >
    <b-form
        class="list-view product-checkout"
        @submit.prevent="handleSubmit(onSubmit)"
    >
      <b-card no-body>
        <b-card-header class="flex-column align-items-start">
          <b-card-title> {{ $t('Please choose a Invoice Address') }}</b-card-title>
        </b-card-header>
        <b-card-body>
          <ValidationProvider rules="required" v-slot="{ errors }" name="address">
            <b-col>
              <div class=" mt-1" v-for="invoiceaddress in INVOICE_ADDRESSES">
                <div class="custom-control custom-radio">
                  <input type="radio"
                         v-model="addressValue"
                         class="custom-control-input"
                         :id="'addressId'+invoiceaddress.id"
                         :value="'addressId'+invoiceaddress.id"
                         @change="setAddressCheckout(invoiceaddress)"
                  >
                  <label :for="'addressId'+invoiceaddress.id" class="custom-control-label">
                    <b-col>
                      <strong>
                        {{ invoiceaddress.firm }}
                      </strong>
                    </b-col>
                    <b-col>
                      <p>
                        {{ invoiceaddress.firstname }},
                        {{ invoiceaddress.name }}
                      </p>
                    </b-col>
                    <b-col>
                      <p class="text-muted">
                        {{ invoiceaddress.street }}
                        {{ invoiceaddress.addressline_2 }}, {{ invoiceaddress.country }}
                      </p>
                    </b-col>
                  </label>
                </div>
              </div>
              <b-alert v-if="errors[0]" show variant="danger" class="py-1 px-2 col-md-6 ">
                {{ $t('Please select a Invoice Address to continue with the Payment Process') }}
              </b-alert>
            </b-col>
          </ValidationProvider>
          <b-col>
            <b-button
                :to="{name: 'invoice-adress-add'}"
                size="sm"
                variant="outline-primary"
                class="btn-tour-skip mr-1 mt-1"
            >
              <feather-icon
                  icon="PlusIcon"
                  size="12"
              />
              <span class="mr-25 align-middle ml-1"> {{ $t('Add another Invoice Address') }}</span>
            </b-button>
          </b-col>
          <b-col>
            <b-button
                variant="success"
                type="submit"
                class="mt-2"
            >
              {{ $t('Continue to Payment') }}
            </b-button>
          </b-col>
        </b-card-body>
      </b-card>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BAlert,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormRadio,
  BFormRadioGroup,
  BRow
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { integer, required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { SchemaForm, useSchemaForm } from 'formvuelate'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    BAlert,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,
    BFormRadio,
    BFormRadioGroup,
    SchemaForm,
    useSchemaForm,

    ValidationProvider,
    ValidationObserver,
    vSelect,
    required,
  },

  setup(_, { emit }) {
    const {
      refFormObserver,
      getValidationState,
      required,
      resetForm,
    } = formValidation(() => {
    })
    const onSubmit = () => {
      emit('next-step')
    }
    return {
      onSubmit,
      refFormObserver,
      resetForm,
      required,
      integer,
    }
  },

  data() {
    return {
      addressValue: null,
      invoiceaddresses: []
    }
  },
  methods: {
    ...mapMutations(['SET_CHECKOUT_ADDRESS', 'SET_TAB_INDEX']),
    ...mapActions(['GET_INVOICE_ADDRESSES']),
    setAddressCheckout(address) {

      this.SET_CHECKOUT_ADDRESS(address)
    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },

  },
  beforeMount() {
    this.GET_INVOICE_ADDRESSES()

  },

  computed: {
    ...mapGetters(['INVOICE_ADDRESSES'])
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';


</style>
