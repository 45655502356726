<template>
  <div class="checkout-items">
    <b-overlay :show="loaded" rounded="sm">
      <b-card
          v-for="item in CART.items"
          :key="item.id"
          class="ecommerce-card"
          no-body
          show-empty
      >
        <b-row>
          <b-col class="md-4">
            <b-link>
              <b-img
                  class="mb-4"
                  :src="require('@/assets/images/illustration/personalization.svg')"
                  size="16"
              />
            </b-link>
          </b-col>
          <b-col class="md-4 mt-2">
            <h6 class="md-4">
              <b-link class="text-body">
                {{ $t('Amount') }}
              </b-link>
            </h6>
            <hr>
            <div class="item-quantity mt-1">
              <span class="quantity-title mb-3">{{ $t('Quantity') }}</span>
              <b-form-input
                  v-model="item.qty"
                  size="sm"
                  class="input-bar mt-1"
                  inline
                  :placeholder="$t('Quantity')"
                  @input="updateCart(item)"
              />
            </div>
          </b-col>
          <b-col class="item-options text-center md-4 mt-2 mr-2">
            <div class="item-wrapper">
              <div class="item-cost">
                <h6 class="item-price">
                  {{ item.qty }} Euro

                </h6>
                <hr>
              </div>
            </div>
            <b-button
                variant="danger"
                class="mt-1 remove-wishlist"
                size="sm"
                @click="deleteFromCart(item.id)"
            >
              <feather-icon
                  icon="XIcon"
                  class="mr-50"
              />
              <span>  {{ $t('Remove') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>

</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCol,
  BFormInput,
  BFormSpinbutton,
  BImg,
  BLink,
  BRow,
  BOverlay
} from 'bootstrap-vue'


import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BBadge,
    BFormSpinbutton,
    BRow,
    BCol,
    BFormInput,
    BOverlay
  },


  data() {
    return {
      loaded: false,
      currentItem: {
        id: '',
        productId: '',
        qty: '',
      },


    }
  },


  methods: {
    ...mapActions(['GET_CART', 'DELETE_CART_ITEM']),





    updateCart(item) {
      this.loaded = false
      let currentItem = {
        qty: item.qty
      }
      let url = `/shop/cart/${item.id}`
      this.$http.put(url, currentItem)
          .then(response => {
            this.GET_CART()
            this.$emit('update:total-amount', 3)

          })
    },

    deleteFromCart(id) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t('You won\'t be able to revert this!'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$i18n.t('Cancel'),
        confirmButtonText: this.$i18n.t('Yes, delete it!'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              this.DELETE_CART_ITEM(id).then(() => {
                this.GET_CART();
                this.$emit('update:total-amount', 4)
              })
            }
          })
    },

  },
  computed: {
    ...mapGetters(['CART']),

  },
  mounted() {
    this.GET_CART()
  },
}

</script>
<style>
.input-bar {
  width: 30% !important;
}
</style>
